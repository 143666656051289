import {find} from 'lodash';
import {
  hideCookiePreferencesFooter,
  showCookiePreferencesFooter,
} from 'shared/cookie-preference/helpers';
import AssetService from 'src/services/AssetService';

export const UPDATE_ADDRESS_EU_STATUS = 'REGISTRATION/UPDATE_ADDRESS_EU_STATUS';

export const USER_FIELDS = [
  'firstName',
  'lastName',
  'birthday',
  'addressLine1',
  'addressLine2',
  'city',
  'subdivision',
  'country',
  'postalCode',
  'addressVerified',
  'confirmedUnverifiedAddress',
  'highSchoolInfo',
  'currentInstitution',
  'onboardingCompletedAt',
  'isUsCitizenOrResident',
  'countryOfCitizenship',
  'followedCollegeIds',
  'signupSkipFollowedCollegesStep',
  'demoType',
  'educationPhase',
  'communityCollegeInfo',
  'mobile',
  'referralSource',
  'mobileVerified',
];

export const USER_FIELDS_TO_OMIT = ['replacementAccountType'];

export const setAddressEUStatus = (addressCountry) => (dispatch) =>
  AssetService.getCountryList(false).then((countriesList) => {
    const country = find(countriesList, {code: addressCountry});

    if (country.inEU) {
      showCookiePreferencesFooter();
    } else {
      hideCookiePreferencesFooter();
    }

    dispatch({type: UPDATE_ADDRESS_EU_STATUS, addressInEU: country && country.inEU});
  });
