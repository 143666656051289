export enum Modals {
  CLUB_CLAIM = 'claim-club',
  VERIFY_LEADERSHIP = 'verify-leadership',
  SCHOLARSHIP_PROGRAM_HIGH_SCHOOL = 'scholarship-program-high-schools',
}

export enum CollegePortalViews {
  DELETE_CAMPAIGN = 'delete-campaign',
}

export enum UserIdentifier {
  ADMIN = 'superadmin',
  ADULT_LEARNER = 'student:adult-learner',
  CC_STAFF = 'communitycollegestaff',
  CC_STUDENT = 'student:community-college',
  CP_USER = 'collegeportaluser',
  DISTRICT_ADMIN = 'districtadmin',
  EDUCATOR = 'educator:high-school',
  HS_COUNSELOR = 'counselor:high-school',
  HS_MENTOR = 'mentor:high-school',
  HS_STUDENT = 'student:high-school',
  HS_TEACHER = 'teacher:high-school',
  PARENT = 'parent',
  UG_STUDENT = 'student:undergraduate',
  MENTOR = 'mentor',
  TEACHER = 'teacher',
  COUNSELOR = 'counselor',
}

// User type constants relating to
// ApplicationHelper#type_of_user.
export enum UserType {
  PUBLIC = 'public',
  EDUCATOR = 'educator',
  CC_STUDENT = 'community-college',
  UNDERGRADUATE_STUDENT = 'undergraduate',
  ADULT_LEARNER = 'adult-learner',
  HS_STUDENT = 'high-school',
  PARENT = 'parent',
  COLLEGE_PORTAL_USER = 'CollegePortalUser',
}

export enum EducationPhase {
  COMMUNITY_COLLEGE = 'community-college',
  HIGH_SCHOOL = 'high-school',
  UNDERGRADUATE = 'undergraduate',
  ADULT_LEARNER = 'adult-learner',
  // below are not really education phases
  TRANSFER = 'transfer',
  DELETE = 'delete',
  EDUCATOR = 'educator',
}

export enum SignUpUserType {
  HIGH_SCHOOL = 'hs-student',
  COMMUNITY_COLLEGE = 'cc-student',
  EDUCATOR = 'educator',
  PARENT = 'parent',
  ADULT_LEARNER = 'adult-learner',
}

export enum LoadState {
  NONE,
  LOADING,
  LOADED,
  FAILED,
}

export enum RegistrationSteps {
  ACHIEVEMENT_SELECT = 'achievementSelect',
  ADDRESS = 'address',
  BIRTHDAY = 'birthday',
  CITIZENSHIP = 'citizenship',
  CONGRATS = 'congrats',
  FOLLOW_COLLEGES = 'followColleges',
  GRADUATION_YEAR = 'graduationYear',
  INSTITUTION = 'institution',
  MOBILE_NUMBER = 'mobileNumber',
  NAME = 'name',
  PELL_ELIGIBILITY = 'pellEligibility',
  TRANSFER_TERM = 'transferTerm',
  HOW_PLANNING_TO_USE = 'howPlanningToUse',
  CASELOAD = 'caseload',
  HOW_HEARD = 'howHeard',
  STUDENT_CONNECTION = 'studentConnection',
}

export enum InstitutionType {
  HIGH_SCHOOL = 'high-school',
  COMMUNITY_COLLEGE = 'community-college',
}

// TODO Don't know why we have this and InstitutionType
export enum PascalInstitutionType {
  HIGH_SCHOOL = 'HighSchool',
  UNDERGRADUATE = 'UndergraduateInstitution',
  COMMUNITY_COLLEGE = 'CommunityCollege',
}

export enum Emojis {
  INSTITUTION = '\u{1F3EB}',
  PARTY = '\u{1F389}',
}

export enum CitizenshipOptions {
  YES = 'yes',
  NO = 'no',
  DECLINE = 'decline',
}

export enum NextStepPhase {
  STEP_INITIAL = 'initial',
  STEP_4_YEAR = 'four-year',
  STEP_CC = 'community-college',
  STEP_OTHER = 'other',
  STEP_UNDECIDED = 'undecided',
  STEP_COMPLETE = 'completed',
  STEP_OTHER_EMAIL = 'other-email',
  STEP_4_YEAR_SURVEY = 'four-year-survey',
  STEP_UNDECIDED_SURVEY = 'undecided-survey',
  STEP_OTHER_SURVEY = 'other-survey',
}

export enum VerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ApplicationStatus {
  PLANNING_TO_APPLY = 'planning-to-apply',
  APPLIED = 'applied',
  ACCEPTED = 'accepted',
  PLANNING_TO_ENROLL = 'planning-to-enroll',
  ENROLLED = 'enrolled',
  NOT_INTERESTED = 'not-interested',
  UNDECIDED = 'undecided',
}

export enum Duration {
  NONE = 0,
  HALF_SECOND = 500,
  SECOND = 1000,
}

export enum EarningStatus {
  EARNING = 'earning',
  SUBMITTED = 'submitted',
  EARNING_DEADLINE_SOON = 'earning_deadline_soon',
  SUBMISSION_PERIOD_DEFERRED = 'submission_period_deferred',
}

export enum NonEarningStatus {
  BOOST_GPA = 'boost_gpa',
  BOOST_TEST_SCORES = 'boost_test_scores',
  DEADLINE_PASSED = 'deadline_passed',
  SCHOLARSHIPS_NOT_YET_AVAILABLE = 'scholarships_not_yet_available',
  LIMITED_SCHOLARSHIPS = 'limited_scholarships',
  COMPLETE_PROFILE = 'complete_profile',
  US_CITIZENSHIP_REQUIRED = 'us_citizenship_required',
  US_CITIZENS_ONLY = 'us_citizens_only',
  NON_US_CITIZENS_ONLY = 'non_us_citizens_only',
  CHURNED = 'churned',
}
